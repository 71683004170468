import { ROLE } from "@/common/const";
import { Book_Type } from "@/common/const/book";

export const menuData = [
  {
    name: "实战",
    icon: "icon-a-3",
    key: "/coach",
    filter: (userInfo: any) =>
      userInfo?.settings?.enabled_course_types?.includes(Book_Type.COACH) || userInfo?.is_trial_user
  },
  {
    name: "学练",
    icon: "icon-a-1",
    key: "/book",
    filter: () => true
  },
  {
    name: "素材",
    icon: "icon-tikuguanli",
    key: "/material",
    filter: (userInfo: any) =>
      ![ROLE.STU, ROLE.INSPECTOR]?.includes(userInfo?.role?.id) || userInfo?.is_trial_user
  },
  {
    name: "报告",
    icon: "icon-baobiao_report",
    key: "/report",
    filter: (userInfo: any) => ![ROLE.STU]?.includes(userInfo?.role?.id)
  },
  {
    name: "报告",
    icon: "icon-baobiao_report",
    key: "/record",
    filter: (userInfo: any) => [ROLE.STU]?.includes(userInfo?.role?.id) || userInfo?.is_trial_user
  },
  {
    name: "设置",
    icon: "icon-a-31shezhi",
    key: "/setting",
    filter: (userInfo: any) => ![ROLE.STU]?.includes(userInfo?.role?.id) || userInfo?.is_trial_user
  }
];
